.showcase {
    margin-bottom: 3rem;
    box-shadow: $box-shadow;

    &-content {
        padding: 1.5rem;
        text-align: center;
    }

    .block-list {
        margin-bottom: 1.5rem;
    }
}
