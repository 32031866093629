.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  margin-top: 10px;
  margin-bottom: 25px;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; } }

