@charset "utf-8";
@import url('https://fonts.googleapis.com/css?family=Montserrat');

$family-sans-serif: 'Montserrat', sans-serif;
$primary: #CC002B !default;

$tabs-link-active-color: $primary;
$tabs-link-active-border-bottom-color: $primary;

@import "../node_modules/bulma/bulma.sass";
@import "layout";
@import "syntax";
@import "showcase";
@import "block-list"
