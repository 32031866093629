.content {
    ul.pagination-list {
        list-style: none;
        margin-left: 0;
        margin-top: 0;

        li+li {
            margin-top: 0;
        }
    }
}

.content-large-font {
    ul.pagination-list {
        list-style: none;
        margin-left: 0;
        margin-top: 0;

        li+li {
            margin-top: 0;
        }
    }
    font-size: x-large;
}

div.highlight {
    margin-bottom: 1rem;
}


@include desktop {
    .navbar-item {
        &.is-hoverable:hover {
            .navbar-dropdown {
                display: block;
            }
        }
    }
    .navbar-logo {
        background: url("../../img/logos/HorizontalInvert@0.5x.png") no-repeat;
        background-size: 172px auto;
        background-position: 7px;
        text-indent: -9999px;
        width: 182px;
    }
    .navbar.off-color
    {
        background-color: #182C4B;
        color: #ffffff;
    }
}

.contents {
    box-shadow: $box-shadow;
    padding: 1.5rem;
    margin-bottom: 3rem;
}

.circular_image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    background-color: white;
    float: center;
    object-fit:cover;
    // margin-left: 125px;
    margin-top: 15px;
    margin-left: 10px;

    display:inline-block;
  }
  .circular_image img{
    width:100%;
    height: auto;
  }

table.customFormat tr td{ 
    line-height: 1.2rem; 
}
