.highlight table td { padding: 5px; }
.highlight table pre { margin: 0; }
.highlight .cm {
  color: #999988;
  font-style: italic;
}
.highlight .cp {
  color: #999999;
  font-weight: bold;
}
.highlight .c1 {
  color: #999988;
  font-style: italic;
}
.highlight .cs {
  color: #999999;
  font-weight: bold;
  font-style: italic;
}
.highlight .c, .highlight .cd {
  color: #999988;
  font-style: italic;
}
.highlight .err {
  color: #a61717;
  background-color: #e3d2d2;
}
.highlight .gd {
  color: #000000;
  background-color: #ffdddd;
}
.highlight .ge {
  color: #000000;
  font-style: italic;
}
.highlight .gr {
  color: #aa0000;
}
.highlight .gh {
  color: #999999;
}
.highlight .gi {
  color: #000000;
  background-color: #ddffdd;
}
.highlight .go {
  color: #888888;
}
.highlight .gp {
  color: #555555;
}
.highlight .gs {
  font-weight: bold;
}
.highlight .gu {
  color: #aaaaaa;
}
.highlight .gt {
  color: #aa0000;
}
.highlight .kc {
  color: #000000;
  font-weight: bold;
}
.highlight .kd {
  color: #000000;
  font-weight: bold;
}
.highlight .kn {
  color: #000000;
  font-weight: bold;
}
.highlight .kp {
  color: #000000;
  font-weight: bold;
}
.highlight .kr {
  color: #000000;
  font-weight: bold;
}
.highlight .kt {
  color: #445588;
  font-weight: bold;
}
.highlight .k, .highlight .kv {
  color: #000000;
  font-weight: bold;
}
.highlight .mf {
  color: #009999;
}
.highlight .mh {
  color: #009999;
}
.highlight .il {
  color: #009999;
}
.highlight .mi {
  color: #009999;
}
.highlight .mo {
  color: #009999;
}
.highlight .m, .highlight .mb, .highlight .mx {
  color: #009999;
}
.highlight .sb {
  color: #d14;
}
.highlight .sc {
  color: #d14;
}
.highlight .sd {
  color: #d14;
}
.highlight .s2 {
  color: #d14;
}
.highlight .se {
  color: #d14;
}
.highlight .sh {
  color: #d14;
}
.highlight .si {
  color: #d14;
}
.highlight .sx {
  color: #d14;
}
.highlight .sr {
  color: #009926;
}
.highlight .s1 {
  color: #d14;
}
.highlight .ss {
  color: #990073;
}
.highlight .s {
  color: #d14;
}
.highlight .na {
  color: #008080;
}
.highlight .bp {
  color: #999999;
}
.highlight .nb {
  color: #0086B3;
}
.highlight .nc {
  color: #445588;
  font-weight: bold;
}
.highlight .no {
  color: #008080;
}
.highlight .nd {
  color: #3c5d5d;
  font-weight: bold;
}
.highlight .ni {
  color: #800080;
}
.highlight .ne {
  color: #990000;
  font-weight: bold;
}
.highlight .nf {
  color: #990000;
  font-weight: bold;
}
.highlight .nl {
  color: #990000;
  font-weight: bold;
}
.highlight .nn {
  color: #555555;
}
.highlight .nt {
  color: #000080;
}
.highlight .vc {
  color: #008080;
}
.highlight .vg {
  color: #008080;
}
.highlight .vi {
  color: #008080;
}
.highlight .nv {
  color: #008080;
}
.highlight .ow {
  color: #000000;
  font-weight: bold;
}
.highlight .o {
  color: #000000;
  font-weight: bold;
}
.highlight .w {
  color: #bbbbbb;
}
.highlight {
  background-color: #f8f8f8;
}
.button0 {
  /*background-color: #4CAF50;*/
  background-color: #a80707;
  border: none;
  color: white;
  padding: 2px 4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 7px;
  font-weight: bold;
  // margin: 2px 1px;
  margin-left: 20px;
  cursor: pointer;
  border-radius: 5px;
}

.button1 {
  /*background-color: #FF6600;*/
  background-color: #a80707;
  border: none;
  color: white;
  padding: 2px 4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 7px;
  font-weight: bold;
  margin: 2px 1px;
  cursor: pointer;
  border-radius: 5px;
}

.button2 {
  /*background-color: #000099;*/
  background-color: #a80707;
  border: none;
  color: white;
  padding: 2px 4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 7px;
  font-weight: bold;
  margin: 2px 1px;
  cursor: pointer;
  border-radius: 5px;
}

.button3 {
  /*background-color: #777777;*/
  background-color: #a80707;
  border: none;
  color: white;
  padding: 2px 4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 7px;
  font-weight: bold;
  margin: 2px 1px;
  cursor: pointer;
  border-radius: 5px;
}

.button4 {
  /*background-color: #DC143C;*/
  background-color: #a80707;
  border: none;
  color: white;
  padding: 2px 4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 7px;
  font-weight: bold;
  margin: 2px 1px;
  cursor: pointer;
  border-radius: 5px;
}

.button5 {
  /*background-color: #DC143C;*/
  background-color: #2d9400;
  border: none;
  color: white;
  padding: 2px 4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 8px;
  font-weight: bold;
  margin: 2px 1px;
  cursor: pointer;
  border-radius: 5px;
}

.button6 {
    /*background-color: #DC143C;*/
    background-color: white;
    // border: none;
    border: 1.5px solid #c9c9c9;
    color: black;
    padding: 4px 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
    margin: 2px 1px;
    cursor: pointer;
    border-radius: 5px;
}

.button6:hover {
  background-color: #a80707;
  color: white;
  border: 1.5px solid #a80707;
}

@media (min-width: 48em) {
  .button0 {
      background-color: #a80707;
      border: none;
      color: white;
      padding: 4px 8px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 10px;
      font-weight: bold;
      // margin: 2px 1px;
      margin-left: 20px;
      cursor: pointer;
      border-radius: 5px;
  }

  .button1 {
      background-color: #a80707;
      border: none;
      color: white;
      padding: 4px 8px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 10px;
      font-weight: bold;
      margin: 2px 1px;
      cursor: pointer;
      border-radius: 5px;
  }

  .button2 {
      background-color: #a80707;
      border: none;
      color: white;
      padding: 4px 8px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 10px;
      font-weight: bold;
      margin: 2px 1px;
      cursor: pointer;
      border-radius: 5px;
  }

  .button3 {
      background-color: #a80707;
      border: none;
      color: white;
      padding: 4px 8px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 10px;
      font-weight: bold;
      margin: 2px 1px;
      cursor: pointer;
      border-radius: 5px;
  }

  .button4 {
      background-color: #a80707;
      border: none;
      color: white;
      padding: 4px 8px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 10px;
      font-weight: bold;
      margin: 2px 1px;
      cursor: pointer;
      border-radius: 5px;
  }

  .button5 {
      /*background-color: #DC143C;*/
      background-color: #2d9400;
      border: none;
      color: white;
      padding: 4px 8px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 10px;
      font-weight: bold;
      margin: 2px 1px;
      cursor: pointer;
      border-radius: 5px;
  }
}


.tag-div {
    padding-bottom: 40px;
};

// The following buttons are used for the tags in the publications page
.button-filterALL-ON {
    background-color: #40E0D0;
    border: 2px solid #40E0D0;
    color: black;
    padding: 4px 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
    margin: 2px 1px;
    cursor: pointer;
    border-radius: 5px;
}

.button-filterALL-ON:hover {
    background-color: lightgray;
}

.button-filterALL-OFF {
    background-color: white;
    border: 2px solid #40E0D0;
    color: black;
    padding: 4px 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
    margin: 2px 1px;
    cursor: pointer;
    border-radius: 5px;
}

.button-filterALL-OFF:hover {
    background-color: lightgray;
}


.button-filter0-ON {
    background-color: #DFFF00;
    border: 2px solid #DFFF00;
    color: black;
    padding: 4px 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
    margin: 2px 1px;
    cursor: pointer;
    border-radius: 5px;
}

.button-filter0-ON:hover {
    background-color: lightgray;
}

.button-filter0-OFF {
    background-color: white;
    border: 2px solid #DFFF00;
    color: black;
    padding: 4px 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
    margin: 2px 1px;
    cursor: pointer;
    border-radius: 5px;
}

.button-filter0-OFF:hover {
    background-color: lightgray;
}

.button-filter1-ON {
    background-color: #FF3333;
    border: 2px solid #FF3333;
    color: black;
    padding: 4px 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
    margin: 2px 1px;
    cursor: pointer;
    border-radius: 5px;
}

.button-filter1-ON:hover {
    background-color: lightgray;
}

.button-filter1-OFF {
    background-color: white;
    border: 2px solid #FF3333;
    color: black;
    padding: 4px 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
    margin: 2px 1px;
    cursor: pointer;
    border-radius: 5px;
}

.button-filter1-OFF:hover {
    background-color: lightgray;
}

.button-filter2-ON {
    background-color: #33FF4C;
    border: 2px solid #33FF4C;
    color: black;
    padding: 4px 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
    margin: 2px 1px;
    cursor: pointer;
    border-radius: 5px;
}

.button-filter2-ON:hover {
    background-color: lightgray;
}

.button-filter2-OFF {
    background-color: white;
    border: 2px solid #33FF4C;
    color: black;
    padding: 4px 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
    margin: 2px 1px;
    cursor: pointer;
    border-radius: 5px;
}

.button-filter2-OFF:hover {
    background-color: lightgray;
}

.button-filter3-ON {
    background-color: #F633FF;
    border: 2px solid #F633FF;
    color: black;
    padding: 4px 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
    margin: 2px 1px;
    cursor: pointer;
    border-radius: 5px;
}

.button-filter3-ON:hover {
    background-color: lightgray;
}

.button-filter3-OFF {
    background-color: white;
    border: 2px solid #F633FF;
    color: black;
    padding: 4px 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
    margin: 2px 1px;
    cursor: pointer;
    border-radius: 5px;
}

.button-filter3-OFF:hover {
    background-color: lightgray;
}

.button-filter4-ON {
    background-color: #CCCCFF;
    border: 2px solid #CCCCFF;
    color: black;
    padding: 4px 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
    margin: 2px 1px;
    cursor: pointer;
    border-radius: 5px;
}

.button-filter4-ON:hover {
    background-color: lightgray;
}

.button-filter4-OFF {
    background-color: white;
    border: 2px solid #CCCCFF;
    color: black;
    padding: 4px 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
    margin: 2px 1px;
    cursor: pointer;
    border-radius: 5px;
}

.button-filter4-OFF:hover {
    background-color: lightgray;
}

.button-filter5-ON {
    background-color: #FFA533;
    border: 2px solid #FFA533;
    color: black;
    padding: 4px 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
    margin: 2px 1px;
    cursor: pointer;
    border-radius: 5px;
}

.button-filter5-ON:hover {
    background-color: lightgray;
}

.button-filter5-OFF {
    background-color: white;
    border: 2px solid #FFA533;
    color: black;
    padding: 4px 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
    margin: 2px 1px;
    cursor: pointer;
    border-radius: 5px;
}

.button-filter5-OFF:hover {
    background-color: lightgray;
}

.button-filter6-ON {
    background-color: #33FFB2;
    border: 2px solid #33FFB2;
    color: black;
    padding: 4px 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
    margin: 2px 1px;
    cursor: pointer;
    border-radius: 5px;
}

.button-filter6-ON:hover {
    background-color: lightgray;
}

.button-filter6-OFF {
    background-color: white;
    border: 2px solid #33FFB2;
    color: black;
    padding: 4px 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
    margin: 2px 1px;
    cursor: pointer;
    border-radius: 5px;
}

.button-filter6-OFF:hover {
    background-color: lightgray;
}

.button-disabled {
    pointer-events: none;
}
