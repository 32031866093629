@charset "utf-8";
@import "../node_modules/bulma/sass/utilities/_all.sass";
@import "../node_modules/bulma/sass/base/_all.sass";

$block-list-separator: 0.25rem !default;

.block-list {
    list-style: none;

    li {
        padding: ($gap / 2);
        background: $light;
        margin-bottom: $block-list-separator;
    }

    &.is-left {
        li {
            text-align: left;
        }
    }


    &.is-centered {
        li {
            text-align: center;
        }
    }

    &.is-right {
        li {
            text-align: right;
        }
    }

    &.is-small {
        li {
            font-size: $small-font-size;
            padding: ($gap / 3);
        }
    }
    
    &.is-normal {
        li {
            font-size: $body-font-size;
        }
    }

    &.is-large {
        li {
            font-size: $size-large;
        }
    }

    @each $name, $pair in $colors {

        $color: nth($pair, 1);
        $color-invert: nth($pair, 2);

        &.is-#{$name} {
            li {
                background: $color;
                color: $color-invert;
            }

            &.is-outlined {
                li {
                    background: transparent;
                    color: $color;
                    border: 1px solid $color;
                }
            }

            &.has-radius {
                li {
                    border-radius: $radius;
                }
            }
        }
    }

}