$footer-background-color: $scheme-main-bis !default;
$footer-color: false !default;
$footer-padding: 3rem 1.5rem 6rem !default;

.footer {
  background-color: #fff;
  border-top: 1px solid #eee;
  padding: $footer-padding;
  @if $footer-color {
    color: $footer-color; } }
